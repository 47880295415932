@import url('https://fonts.googleapis.com/css2?family=Crete+Round&family=Overpass:wght@600&family=PT+Sans+Caption:wght@700&family=Yantramanav:wght@700&display=swap');


:root{
    --note-background-color: rgb(255, 255, 255);
}


.modalOverlay {
    position: fixed;
    top : 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.06);
    z-index: 1000;
    backdrop-filter: blur(1px);
}



.modalMain {
    padding-top: 1em;
    box-shadow: 1px 1px 15px rgb(70, 70, 70);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--note-background-color);
    z-index: 1000;
    border-radius: 11px;
    width: 43em;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #f9fbff;
}

.delete-modal {
    height: 25em;
}

.modal-banner {
    padding-bottom: 1em;
    position: relative;
    border-radius: 9px 9px 0px 0px;
    width: 90%;
    height: 5rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid grey;
}

.leave-suggestions-container {
    padding-bottom: 0;
}

.modal-laeve {
    padding-top: 0;
}
.modal-banner span {
    font-size: 1.7em;
    font-weight: 200;
    color: #456BBF;
}

.modal-content {
    width: 100%;
    display: flex;
    max-height: 40em;
    align-items: center;
    flex-direction: column;
    margin-bottom: 2em;
    overflow: auto;
    padding-bottom: 2em;
}

.modal-content-delete {
    width: 100%;
    display: flex;
    height: 80%;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 2em;
}



.review-card {
    height: fit-content;
    width: 85%;
    background-color: white;
    border-radius: 9px;
    box-shadow: 1px 2px 10px grey;
    margin-top: 2em;
}

.review-card-top {
    border-radius: 9px 9px 0px 0px;
    height: 2em;
    width: 100%;
    background-color: #EAEAEA;
}

.review-year, .review-month, .review-date {
    margin-left: .3em;
}

.review-card-body {
    height: fit-content;
    width: 100%;
    border-radius: 0px 0px 9px 9px;
    display: grid;
    grid-template-columns: 90% 10%;
}

.review-card-info {
    padding-left: 1em;
    width: fit-content;
    height: 100%;
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
}

.review-dashboard-info {
    padding-left: 1em;
    width: 100%;
    height: 100%;
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
}

.review-card-linenum {
    margin-left: auto;
}

.review-card-linenum > span {
    color: rgb(124, 124, 124);
    margin-right: 5.5em;
    font-weight: 500;
}
.review-card-name {
    height: 100%;
    width: fit-content;
}

.review-date-container-ago, .review-date-container-normal {
    cursor: pointer;
    font-size: .9em;
    display: flex;
    flex-direction: row;
    color: rgb(107, 107, 107);
    font-weight: 200;
}

.review-date-container-normal {
    display: none;
}

.review-card-name {
    margin-right: 1em;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.review-card-name  p {
    font-size: 1.25em;
    color: #456BBF;
    font-weight: 500;
}

.review-close {
    position: relative;
    width: 100% !important;
    height: 100%;
}

.review-p {
    margin: 0;
    padding: 1em;
    padding-left: 3em;
    font-weight: 300;
    line-height: 1.5em;
}

.review-card-delete {
    display: flex;
    align-items: center;
    justify-content: center;
}

.trash-icon {
    fill: #707070;
    cursor: pointer;
    transition: all .2s ease;
}
.trash-icon:hover {
    transform: scale(1.3);
}


.modal-close-btn {
    cursor: pointer;
    position: absolute;
    right: -1em;
    height: 100%;
    width: 8%;
    transition: all .9s ease;
}



.review-close div:nth-child(1) {
    height: 1px;
    width: 50%;
    background-color: black;
}
.review-close div:nth-child(2) {
    height: 1px;
    width: 50%;
    background-color: black;
}

.review-close:hover {
    transform: scale(1.1);
}

.review-delete-container {
    width: 100%;
    height: 4em;
    display: none;
    justify-content: center;
    align-items: center;
}


.review-delete-card, .review-post-card{
    border-radius: 10px 10px 0px 0px;
    width: 40%;
    height: 100%;
    background-color: rgb(218, 218, 218);
    display: flex;
    justify-content: center;
    align-items: center;
}

.circle-btn {
    border-radius: 10px 10px 10px 10px;
    margin-top: -3em;
}

.modal-undo-btn {
    cursor: pointer;
    color: grey;
    font-size: 1em;
    font-weight: 600;
    margin-right: 1.5%;
    border-radius: 10px;
    border: none;
    background-color: rgb(255, 255, 255);
    height: 80%;
    width: 45%;
}
.modal-delete-btn {
    cursor: pointer;
    color: white;
    font-size: 1em;
    font-weight: 600;
    margin-left: 1.5%;
    border-radius: 10px;
    border: none;
    background-color: rgba(255, 0, 0, 0.788);
    height: 80%;
    width: 45%;
}
.modal-undo-btn:hover {
    background-color: rgb(248, 247, 247);
}

.modal-delete-btn:hover {
    background-color: rgb(255, 0, 0);
}

.review-form {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 3em;
    height: 100%;
    width: 93%;
}

.review-name-input {
    color: grey;
    font-size: 1em;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin-top: 1em;
    margin-bottom: 2em;
    height: 15%;
    border: none;
    background-color: rgb(236, 236, 236);
    border-bottom: 1px solid black;
    padding-left: 1em;
}

.review-name-input:focus, .review-input:focus  {
    outline:none;
}

.review-input {
    color: grey;
    padding-top: .5em;
    font-size: 1em;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    width: 80%;
    height: 50%;
    resize: none;
    border: none;
    background-color: rgb(236, 236, 236);
    border-bottom: 1px solid black;
    padding-left: 1em;
    padding-right: 1em;
}

.review-post-container {
    width: 100%;
    height: 4em;
}

.review-submit-btn {
    cursor: pointer;
    color: white;
    margin-top: -1em;
    float: right;
    margin-right: 5em;
    height: 90%;
    width: 17%;
    border: none;
    border-radius: 20px;
    background-color: #456BBF;
    box-shadow: 0px 4px 10px #456BBF;
    font-size: 1em;
    transition: all .2s ease;
}

#error-p {
    color: red;
}
.review-submit-btn:hover {
    transform: scale(1.05);
    box-shadow: 0px 4px 15px #2d5dc5;
}

.comments-guidline {
    color: grey;
    font-size: .8em;
}

@media screen and (max-width: 768px) {
    .modal-content {
        max-height: 20em;
    }
    .modalMain {
        width: 85%;
    }
    .modal-banner {
        width: 65%;
    }
    .review-close {
        width: 100%;
    }
    .review-close div:nth-child(1) {
        height: 1px;
        width: 90%;
        background-color: black;
    }
    .review-close div:nth-child(2) {
        height: 1px;
        width: 90%;
        background-color: black;
    }
    .review-delete-card {
        width: 80%;
    }
    .trash-icon {
        fill: #707070;
        cursor: pointer;
        transition: all .2s ease;
        margin-right: 1em;
    }

    .delete-modal span {
        font-size: 1.5em;
    }
    
    .review-post-container {
        width: 100%;
        height: 4em;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .review-submit-btn {
        margin-right: 0;
        width: 30%;
    }
    .review-form {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        padding-left: 2em;
        height: 100%;
        width: 93%;
    }
    .comments-guidline {
        margin-left: 1em;
    }
}


