.banner-text-container {
    width: 100%;
    height: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home-title-container {
    width: 100%;
    display: flex;
    align-items: center;
}

.home-title-c {
    display: flex;
    justify-content: center;
}

.authot-name-c {
    position: relative;
}

.home-title-container div {
    width: 50%;
}

.intro-banner-myself {
    width: 65%;
    height: 7em;
}


.intro-banner-myself > p {
    font-size: .9em;
    color: grey;
}


.author-name {
    font-family: 'Times New Roman', Times, serif;
    line-height: 2em;
    font-size: 1.3em;
    position: absolute;
    right: 50%;
}

.intro-banner-text {
    text-align: left;
    width: 65%;
    font-family: 'Times New Roman', Times, serif;
    line-height: 2em;
    font-size: 1.3em;
}

.bannter-hr {
    width: 65%;
}



.about-banner {
    color: #4F71BE;
    font-family: 'Times New Roman', Times, serif;
    font-size: 2em;
}

@media screen and (max-width: 768px)  {
    .about-banner {
        margin-top: -1em;
        color: rgb(79, 113, 190);
        font-family: 'Times New Roman', Times, serif;
        font-size: 2em;
        margin-left: 18%; 
    }
    .bannter-hr {
        width: 85%;
    }

    .intro-banner-text {
        text-align: left;
        width: 82%;
        font-family: 'Times New Roman', Times, serif;
        line-height: 2em;
        font-size: 1.3em;
    }
}



@media screen and (max-width: 1254px) {
    .home-title-container {
        margin-top: 7em;
    }
}