@import url('https://fonts.googleapis.com/css2?family=Crete+Round&family=Overpass:wght@600&family=PT+Sans+Caption:wght@700&family=Prompt&display=swap');
body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}
.input-form {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient( 45deg, #2953b4 50%, #292423 50%);
}

.input-card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 30em;
    height: 30em;
    background-color: rgb(255, 255, 255);
    border-radius: 9px;
    box-shadow: 8px 8px 16px;
}



.input-field-login {
    font-family: 'PT Sans Caption', sans-serif;
    font-size: 1em;
    height: 50%;
    width: 100%;
    background: none;
    outline: none;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid grey;
}

.input-field-login:hover {
    border-bottom: 2px solid grey;
}

.input-field-login::placeholder {
    color: rgb(85, 85, 85);
}

/* .input-field-login:nth-of-type(1) {
    border-radius: 6px 6px 0px 0px;
}
.input-field-login:nth-of-type(2) {
    border-radius: 0px 0px 6px 6px;
} */

.login-btn{
    font-family: 'PT Sans Caption', sans-serif;
    font-weight: bold;
    font-size: 1.1rem;
    color: white;
    border-radius: 6px;
    border: none;
    text-align: center;
    height: 10%;
    width: 70%;
    margin-top: 3rem;
    background-color: #6de0b4;
}

.login-btn:hover {
    background-color: #50dda7;
}


.input-title {
    font-weight: 700;
    font-family: 'PT Sans Caption', sans-serif;
    margin-bottom: 1rem;
    font-size: 2rem;

}

.input-align {
    height: 15%;
    width: 70%;
    display: block;
}

.input-icon {
    height: 1rem;
}

.login-des {
    font-size: 0.95rem;
    font-family: 'PT Sans Caption', sans-serif;
    text-align: center;
}

