.contact-container {
    position: absolute;
    bottom: -25%;
    left: 50%;
    transform: translate(-50%,-50%);
    min-width: 18em;
    height: 30em;
    background-color: white;
    padding: 2em;
    border-radius: 10px;
    box-shadow: 0px 1px 9px black;
    z-index: 1000;
}

.contact-container-inner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}

.contact-close {
    position: absolute;
    right: 1em;
    top: 1em;
    height: 9%;
    width: 5%;
}

.contact-close {
    position: absolute;
    right: 1em;
    top: 1em;
    height: 9%;
    width: 5%;
    z-index: 2;
    transition: all .1s ease-in-out;
    cursor: pointer;
}

.contact-close-relative {
    position: relative;
    width: 100%;
    height: 100%;
}

.contact-close:hover {
    transform: scale(1.2);
}

.contact-close-relative div:nth-child(1) {
    position: absolute;
    top: 50%;
    left: 11%;
    transform: translate(-50%, -50%);
    transform: rotate(135deg);
    height: 1.5px;
    width: 80%;
    background-color: black;
}
.contact-close-relative div:nth-child(2) {
    position: absolute;
    top: 50%;
    left: 11%;
    transform: translate(-50%, -50%);
    transform: rotate(45deg);
    height: 1.5px;
    width: 80%;
    background-color: black;
}

/* .contact-left {
    display: flex;flex-direction: column;
    align-items: center;
    padding: 1em;
    width: 22vw;
    border-right: 1px solid black;
} */

.contact-container h1 {
    margin-bottom: 1em;
    font-weight: 300;
    color: #647CCF;
    font-size: 1.6em;
    z-index: 1;
    position: absolute;
    top: 5%;
    left: 12%;
}


.contact-icon {
    width: 100%; 
}


.contact-right {
    width: 100%;
    height: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.contact-right form{
    height: 80%;
    padding-top: 3em;
    width: 80%;
    border-top: 1px solid grey;
}

.contact-right form input {
   
    padding: .5em;
    font-size: 1em;
    border: none;
    border-radius: 5px;
    height: 17%;
    background-color: rgba(223, 227, 238, 0.9);
    width: 100%;
}

.contact-right form input:nth-child(1) {
    margin-bottom: 1em;
}
.contact-right form input:nth-child(2) {
    margin-bottom: 2em;
}
.contact-right form textarea {
    resize: none;
    padding: .5em;
    font-size: 1em;
    border: none;
    font-family: Arial, Helvetica, sans-serif;
    border-radius: 5px;
    background-color: rgba(223, 227, 238, 0.9);
    width: 100%;
    height: 9em;
}

.contact-submit-btn {
    background-color: #647CCF;
    border: none;
    border-radius: 20px;
    width: 8em;
    color: white;
    font-size: 1.05em;
    font-weight: bold;
    height: 2.5em;
    box-shadow: 0px 4px 10px rgba(84, 125, 229, 0.7);
    transition: all 0.2s ease-in-out;
}

.contact-submit-btn:hover {
    transform: scale(1.05);
    box-shadow: 0px 6px 18px rgba(63, 108, 224, 0.7);
}

.contact-btn-container {
    margin-top: 1em;
    display: flex;
    justify-content: center;
}

.contact-modal-overlay {
    position: fixed;
    top : 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.06);
    z-index: 1000;
    backdrop-filter: none;
}