.comment-section-container {
    width: 100%;
    min-height: 10em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

.comment-tile {
    width: 100%;
    height: 2em;
    padding-left: 6em;
    margin-bottom: 2em;
}
.comment-tile > span {
    font-size: 1.7em;
    float: left;
    text-decoration: underline;
    color: rgb(66, 66, 66);;
}

.comment-section-body {
    width: 100%;
    height: 100%;   
}

.comment-hr-container {
    width: 100%;
    padding-left: 10em;
    display: flex;
    flex-direction: column
}

.comment-hr-container > div {
    width: 100%;
}
.comment-hr-container > div > hr{
    width: 60%;
    float: left;
}

.comment-hr-container > p {
    font-size: 1.4em;
    color: rgb(66, 66, 66);;
}


.comment-input-section {
    padding-left: 6em;
    width: 80%;
    height: 40%;   
    display: flex;
    margin-bottom: 2em;
}

.comment-display-seciton {
    padding-left: 10em;
    width: 60%;
}

.input-e-comments {
    margin-left: 10em;
}

.comment--hr-spec {
    width: 60vw;
}


@media screen and (max-width: 857px) {
    .comment-display-seciton {
        padding-left: 8em;
        width: 60%;
    }
    .comment-tile {
        width: 100%;
        height: 2em;
        padding-left: 10em;
        margin-bottom: 2em;
    }
    .comment-input-section {
        padding-left: 10em;
        width: 40em;
        height: 40%;   
        display: flex;
        flex-direction: column;
        margin-bottom: 2em;
    }
    .comment-input-name, .comment-input-comment {
        margin-bottom: 1em;
    }

    .comment--hr-spec {
        width: 80%;
    }
    
}

.comment-input-name {
    color: rgb(66, 66, 66);;
    padding-left: .5em;
    font-size: 1em;
    width: 15%;
    height: 1.5em;
    margin-right: 2em;
    background-color: rgb(226, 226, 226);
    border: none;
    border-bottom: 1px solid grey;
}
.comment-input-comment {
    color: rgb(66, 66, 66);;
    font-size: 1.1em;
    padding: .5em;
    resize: none;
    width: 50%;
    margin-right: 2em;
    height: 4em;
    background-color: rgb(226, 226, 226);
    border: none;
    border-bottom: 1px solid grey;
}


.comment-submit-btn {
    font-size: 1.1em;
    height: 2.2em;
    width: 7em;
    border-radius: 20px;
    border: none;
    background-color: #647CCF;
    color: white;
    box-shadow: 0px 4px 10px 1px #647CCF;
}

.comment-submit-btn:hover {
    background-color: #536fd4;
    box-shadow: 0px 4px 10px 2px #4f6bcf;
}