@import url('https://fonts.googleapis.com/css2?family=Source+Serif+4:wght@600&display=swap');

:root {
    --font-family: 'Times New Roman', Times, serif;
    --color-blue: #4F71BE;
}

.intro-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100%;
}
.intro-page {
    width: 60%;
    height: 80%;
}


@media screen and (max-width: 1254px), screen and (max-height: 658px) {
    .intro-page {
        width: 80%;
        height: 80%;
    }
}
.introduction-title {
    font-size: 1.8rem;
    margin-top: 8rem;
    margin-bottom: 4rem;
    font-family: var( --font-family);
    color: var(--color-blue);
}

.introduction-p {
    font-size: 1.2rem;
    font-family: var( --font-family);
    margin: 2rem 0rem 4rem 0rem;
    line-height: 230%;
    text-align: left;
}



.g1 {
    font-family: var( --font-family);
    float: left;
    color: var(--color-blue);
    font-size: 1.5rem;
}

.i-special {
    width: 70%;
    margin: 2rem 3rem 0rem 3rem;
}

@media screen and (max-width: 1000px) { /* smaller screen */
    .i-special {
        width: 80%;
        margin: 7rem 5rem 0rem 2rem;
    }
}

.i-r-p {
    margin-top: -5rem;
    float: right;
}

#i-upper {
    margin-top: 6rem;
}

.t-bold {
    font-weight: 800;
}

.i-center-para {
    margin-top: -4rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 4rem;
    justify-content: center;
}

@media screen and (max-width: 530px) {
    .i-center-para {
        margin-top: -4rem;
        display:list-item;
        list-style: none;
        margin-bottom: 10rem;
    }
    
}

.intro-short-hr {
    width: 60%;
    margin-bottom: -2rem;
}

.i-flex-p {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.i-f {
    flex: 1;
    margin: 0rem 1rem;
    
}

.i-f-s {
    flex: 1;
    margin: 0rem 1rem;
    font-size: 16px;
}

@media screen and (max-width: 835px) {
    .i-flex-p {
        flex-wrap: wrap;
    }
}

.i-c-name {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--color-blue);
}

#i-s-p-center {
    margin-left: 7rem;
}

.i-spa-right {
    float: right;
}

#intro1-citations {
    font-size: 1.5rem;
    color: var(--color-blue);
    font-family: var(--font-family);
    
}

.citations-holder {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    font-family: var(--font-family);
    font-size: 1rem;
    text-align: left;
}

.move-button-i {
    /* centers the buttons */
    display: flex;
    justify-content: center;

}
 .i-btn-top {
     margin-top: 7rem;
     margin-bottom: -3em;
 }

.i-p-btn {
    font-family: var( --font-family);
    font-weight: 500;
    border: none;
    border-radius: 9px;
    font-size: 1.1rem;
    color: white;
    width: 8rem;
    height: 3rem;
    background: var(--color-blue);
    cursor: pointer;
}

.i-p-btn:hover {
    border: 2px solid #4F71BE;
    background: #3e63bb;
}

.i-p2c {
    margin-right: 10rem;
    text-align: center;
}

 .i-center-para-cit {
    margin-top: -4rem;
 }

 .i-ci {/* line height for citation quotes */
    margin-top: 4rem;
    line-height: 20px;
    width: 60%;
    font-size: 1rem;
}
.right-float-i-c {
    margin-left: 17rem;
}

.i-next {
    margin-top: 6rem;
}

.i-top{
    align-items:flex-start;
    margin-top: 5rem;
}

.i-sp-s {
    font-size: 18px;
}

/* .i-p2c-r {
    margin-left: 20rem;
} */

.sup-link {
    width: 100%;
    height: 100%;
    text-decoration: underline;
    color: blue;
    cursor: pointer;
    position: relative;
}

.btn-i-top {
    margin-top: -5rem;
}

.intro-p2-f {
    margin-top: 5rem;
}

.sup-link > span::after {
    z-index: 1;
    line-height: 25px;
    box-shadow: 2px 2px 6px grey;
    padding: .5em;
    color: black;
    margin: auto;
    border-radius: 2px;
    border: 1px solid black;
    position: absolute;
    width: 20em;
    background-color: rgb(255, 255, 255);
    content: attr(data-tip);
    margin-left: 1em;
    display: none;
    word-wrap: break-word; /* so the text doesn't flow out */
    left: .5rem;
}

.sup-link > span:hover::after {
    display: block;
}

.special-fn > span:hover::after {
    display: none;
}

.special-fn:hover #intro-hover-special, .special-fn:hover #intro-hover-special-wider {
    display: flex;
    
}


#intro-hover-special, #intro-hover-special-wider{
    border-radius: 2px;
    border: 1px solid black;
    position: absolute;
    color: black;
    width: 20em;
    height: fit-content;
    background-color: rgb(255, 255, 255);
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 25px;
    box-shadow: 2px 2px 6px grey;
    padding: .5em;
    margin: auto;
    word-wrap: break-word; /* so the text doesn't flow out */
    left: .5rem;
    display: none;
}

#intro-hover-special {
    height: 12em;
}

#intro-hover-special-wider {
    width: 30em;
    flex-direction: column;
}
#fn-b-center {
    margin-top: -1em;
}
#fn33-b {
    margin-top: -1em;
}


.title-name-dan {
    margin-top: 1em
}

.citations-page2 {
    width: 60vw;
    height: 100%;
    padding-left: 3em;
    padding-right: 3em;
}

.comment-holder {
    width: 70vw;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
.move-button-test {
    width: 100%;
    height: 4em;
}

@media screen and (max-width: 1254px) {
    .comment-holder {
        width: 100vw;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start
    }
    .btn-i-top {
        margin-top: 0rem;
    }
}

@media screen and (max-width: 857px) {
    .comment-holder {
        width: 40em;
        display: block;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .citations-page2 {
        width: 90%;
        height: 100%;
        padding-left: 3em;
        padding-right: 3em;
    }
}