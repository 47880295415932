.working-title {
   color: #4F71BE;
   font-size: 2.1em; 
   text-align: center;
   line-height: 2em;
}

.working-title-container > p {
    color: #4F71BE;
   font-size: 1.2em; 
   text-align: center;
   line-height: 2em;
   font-weight: 500;
}

.working-title-container {
    width: 100vw;
    height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.img-working {
    width: 50%;
}

.intro-title-i-2 {
    margin-top: .3em;
    margin-bottom: -1.2em;
}

@media screen and (max-width: 1254px) {
    .working-title-container {
        margin-top: 6em;
    }

    .working-title {
        font-size: 1.8em;
    }
}