.footer-banner-container {
    position: relative;
    width: 100%;
}


.footer-container {
    margin-top: 8em;
    width: 100%;
    height: 22em;
    background-color: #4F71BE;
    display: flex;
    align-items: center;
    flex-direction: row;
}


.curved-div {
    background-color: red;
}

.footer-lower {
    height: fit-content;
    border: 1px solid transparent;
    background-color: #4F71BE;
}

.footer-title-info {
    height: 100%;
    width: 40%;
    display: flex;
    flex-direction: column;
    /* background-color: white; */
}


.footer-title-info h1{
    font-size: 2.3em;
    color: white;
    margin: 2em 0em 2em 4em;
}

.footer-title-info p{
    font-size: 1.1em;
    line-height: 1.4em;
    color: white;
    margin: 2em 0em 2em 9em;
}


.footer-books {
    height: 100%;
    width: 15%;
    /* background-color: grey; */
}

.footer-books h2 {
    font-size: 1.3em;
    color: white;
    margin: 4em 0em 2em -6em;
}

.footer-books-container {
    align-items: center;
    margin-top: 5em;
    margin-left: -7em;
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    grid-row-gap: 1em;
}


@media screen and (max-width: 1472px) {
    .footer-books-container {
        align-items: center;
        margin-top: 5em;
        margin-left: 2em;
        display: grid;
        grid-template-columns: 20% 20%;
        grid-row-gap: 1em;
    }

    .footer-books h2 {
        font-size: 1.3em;
        color: white;
        margin: 3.8em 0em -2em 1em;
    }
    
}

.footer-books-container span{
    cursor: pointer;
    font-weight: 500;
    color: white;
} 

.footer-intro {
    height: 100%;
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: red; */
}

.footer-intro h2 {
    cursor: pointer;
    margin-top: -1em ;
    font-size: 1.3em;
    color: white;
}

#f-i-f {
    cursor: pointer;
    font-weight: 500;
    color: white;
    margin-top: 3em;
}

#f-i-s {
    cursor: pointer;
    font-weight: 500;
    color: white;
    margin-top:3em
}

.footer-download {
    height: 100%;
    width: 25%;
    display:flex;
    justify-content: center;
    flex-direction: column;
    /* background-color: blue; */
}

.footer-download h2 {
    cursor: pointer;
    margin-top: 1.3em;
    margin-left: 2em;
    margin-bottom: 7em;
    font-size: 1.3em;
    color: white;
}

.footer-line {
    width: 86%;
    border-color: white;
}


.footer-lower p {
    color: white;
    font-size: .8em;
    margin-left: 10em;
    margin-top: 2em;
}

.footer-contact-btn {
    cursor: pointer;
    margin-left: -.7em;
    background-color: transparent;
    width: 16em;
    height: 4em;
    border: .4em solid #F7FC19;
    border-radius: 10em;
    color: rgb(247, 252, 25);
    font-size: 0.8em;
    font-weight: 900;
    transition: all .2s ease;
}

.footer-contact-btn:hover {
    transform: scale(1.05);
}

.footer-contact-btn:active {
    color: white;
    background-color: rgba(247, 252, 25, 0.2);
}

@media screen and (max-width: 1124px) {
    .footer-title-info p{
        font-size: 1.1em;
        line-height: 1.4em;
        color: white;
        margin: -2em 0em 2em 9em;
    }
}


@media screen and (max-width: 768px)  {
    .footer-container {
        margin-top: 2em;
        width: 100%;
        height: fit-content;
        background-color: #4F71BE;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .footer-title-info {
        width: 80%;
    }
    .footer-title-info h1, .footer-title-info p {
        margin: 1em 0em 0em 0em;
    }
    .footer-books {
        width: 80%;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
    }
    .footer-books h2 {
        margin-left: 0em;
        margin-top: 2em;
    }
    .footer-books-container {
        align-items: center;
        margin-left: 2.8em;
        display: grid;
        grid-template-columns: 20% 20% 20% 20% 20%;
        grid-row-gap: 1em;
    }

    .footer-intro {
        height: 100%;
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        /* background-color: red; */
    }
    
    .footer-intro h2 {
        cursor: pointer;
        margin-top: 2em;
        margin-left: 0;
        font-size: 1.3em;
    }
    .footer-intro > span {
        margin-top: 1em !important;
        margin-left: 2.8em;
    }

    .footer-download {
        height: 100%;
        width: 80%;
        display:flex;
        justify-content: flex-start;
        /* background-color: blue; */
    }

    .footer-download h2 {
        cursor: pointer;
        font-size: 1.3em;
        margin-left: 0;
        margin-top: 2em;
        color: white;
        margin-bottom: 2em;
    }
    
    .footer-contact-btn {
       transform: scale(.9);
       margin-left: 2em;
    }

    .footer-lower p {
        color: white;
        font-size: .8em;
        margin-left: 3em;
        margin-top: 2em;
    }
}

.footer-info-container {
    width: 100%;
    height: 7em;
    display: flex;
    flex-direction: row;
}

#footer-me > a{
    color: white;
}