

.i-title-intro {
    margin-top: 3rem;
    text-align: center;
    font-size: 2.5rem;
    color: #4F71BE;
    font-family: 'Times New Roman', Times, serif;
}

@media screen and (max-width: 1254px) {
    .i-title-intro {
        margin-top: 2em;
    }
    .intro-title-i-2 {
        margin-top: 2em !important;
    }
}

.intro-link-a:visited {
    color: inherit;
}

.intro-link-a {
    text-decoration: none;
}

.i-changer {
    height: 20%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
}

.i-changer-list {
    margin-right: 2.2rem;
}


.i-changer-list li {
    font-weight: 700;
    font-family: 'Times New Roman', Times, serif;
    font-size: 1.9rem;
    line-height: 4rem;
    display: inline-block;
    list-style: none;
    margin: 0rem 4rem;
    color: #4F71BE;
    transition: .5s ease;
}




.i-changer-list hr {
    border: 1px solid linear-gradient(to right, #4F71BE 50%, white 50%);
    border-radius: 100px;
    width: 82%;
    height: 4px;
    background: linear-gradient(to right, #4F71BE 50%, white 50%);
}





@media screen and (max-width: 836px) {
    .i-changer-list li{
        font-size: 1rem;
    }

    .i-changer-list li:hover {
        font-size: 1.4rem;
    }
    .i-title-intro {
        font-size: 2.7rem;
    }
}


#i-first-page::after, #i-second-page::after {
    content: '';
    width: 0%;
    height: 3px;
    background: #4F71BE;
    display: block;
    margin: auto;
    transition: .5s;
}

#i-first-page:hover::after, #i-second-page:hover::after {
    width: 80%;
}

#i-first-page , #i-second-page {
    cursor: pointer;
}

.login-status {
    font-family: 'Times New Roman', Times, serif;
    margin-right: 4rem;
    color: grey;
    position: absolute;
    right: 4em;
}

.intro-title-i-2 {
    margin-top: .3em;
    margin-bottom: -1.2em;
}